import * as React from "react"
import { Button, Container } from "react-bootstrap"
import FileIcon from '../components/icons/file'
import SearchIcon from '../components/icons/search'
import Layout from "../components/layout";
import BigLogo from "../components/bigLogo";

const NotFoundPage = () => {
  return (
    <Layout>
      <BigLogo />
      <Container className="p-5">
        <h1 class="text-center">Nie znaleźliśmy strony której szukasz</h1>
      </Container>
      <Container className="my-3 parallax d-flex flex-column flex-sm-row p-3 justify-content-around align-content-center flex-wrap">
        <Button variant="primary" size="lg" onClick={jumpToContactPage}>
          <FileIcon />Skontaktuj się z nami
        </Button>
        <Button id="corobimy" variant="primary" size="lg" onClick={jumpToStartPage}>
          <SearchIcon />Wróć na stronę główna
        </Button>
      </Container>
    </Layout>
  )
}

const jumpToContactPage = () => {
  window.location.href = '/kontakt';
}

const jumpToStartPage = () => {
  window.location.href = '/';
}

export default NotFoundPage
