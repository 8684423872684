import * as React from "react";
import { Container } from "react-bootstrap";
import Envelope from './icons/envelope';
import Phone from './icons/phone';
import Facebook from './icons/facebook';
import style from "./bigLogo.module.scss";

const BigLogo = () => {
  return (
    <Container className="p-3 d-flex flex-column align-items-center">
      <h1 className={style.mainLogo}>onvo</h1>
      <h4 className="text-center">deweloper działek pod farmy fotowoltaiczne</h4>
      <Container className="d-flex flex-column flex-sm-row p-3 justify-content-center align-content-center flex-wrap fs-6">
        <div className="px-3 py-1">
          <a href="mailto:biuro@onvo.pl" className={style.iconedLinkes}>
            <Envelope />biuro@onvo.pl
          </a>
        </div>
        <div className="px-3 py-1">
          <a href="https://www.facebook.com/OnvoBiuro/" className={style.iconedLinkes}>
          <Facebook />OnvoBiuro
          </a>
        </div>
        <div className="px-3 py-1">
          <a href="tel:+48789236034" className={style.iconedLinkes}>
            <Phone />789 236 034
          </a>
        </div>
      </Container>
    </Container>
  )
};

export default BigLogo;